
import { defineComponent, nextTick, onMounted } from "vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import routeNames from "@/web/router/routeNames";
import ThankYouBackground from "./ThankYouBackground.vue";
import { importGlobalAsyncScript } from "@/shared/utils/scriptUtils";
import { analyticsTrackCartCheckoutFinish } from "@/shared/utils/analytics";
import { MERCHANT_PAYMENT_EMAIL } from "@/shared/utils/constants";

const PARTY_JS_SCRIPT_SRC =
  "https://cdn.jsdelivr.net/npm/party-js@latest/bundle/party.min.js";
const PARTY_JS_SCRIPT_ID = "party-js-script";
const PARTY_JS_CONFETTI_CONFIG = { count: 30, spread: 40 };

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const router = useRouter();

    onMounted(() => {
      analyticsTrackCartCheckoutFinish();

      // Try and create party.js script, then trigger the confetti
      importGlobalAsyncScript(PARTY_JS_SCRIPT_SRC, PARTY_JS_SCRIPT_ID, () => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const _window = window as any;

        if (_window.party !== undefined) {
          nextTick(() => {
            setTimeout(() => {
              _window.party.confetti(
                document.querySelector(".thank-you-page__content"),
                PARTY_JS_CONFETTI_CONFIG
              );
            }, 300); // set timeout for performance
          }); // nextTick to run only when entire component is rendered (for performance)
        }
      });
    });

    const goToDashboard = () => {
      router.push({ name: routeNames.profile });
    };

    return { t, goToDashboard, MERCHANT_PAYMENT_EMAIL };
  },
  components: { PageLayout, ThankYouBackground },
});

import attempt from "lodash/attempt";

/**
 * Creates and appends `<script>` tag in `<body>`, then runs the callback function onload.
 * Also checks if the script is already loaded in DOM, in which case won't re-import the script.
 * @param url The URL of the resource.
 * @param id The id of the `script` tag. Used to check whether it is already loaded or not.
 * @param callback Function to call on `script.onload`
 */
export const importGlobalAsyncScript = (
  url: string,
  id: string,
  callback
): HTMLElement => {
  const existingScript = window.document.getElementById(id);

  // If already loaded in DOM, then immediately run the callback
  if (existingScript) {
    attempt(callback);
    return existingScript;
  }

  // Else, create a new one, then run the callback on load
  const script = window.document.createElement("script");

  script.id = id;
  script.setAttribute("src", url);
  script.type = "text/javascript";
  script.async = true;
  // anonymous function wrapper required because if not, callback is immediately called
  script.onload = () => attempt(callback);

  // Append in body
  window.document.body.prepend(script);

  return script;
};

import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "thank-you-page__content text-center" }
const _hoisted_2 = { class: "mb-8" }
const _hoisted_3 = { class: "mb-8" }
const _hoisted_4 = { class: "thank-you-page__payment-message mb-8" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ThankYouBackground = _resolveComponent("ThankYouBackground")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, { class: "thank-you-page" }, {
    complexBackground: _withCtx(() => [
      _createVNode(_component_ThankYouBackground)
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_a_typography_title, {
          level: 2,
          class: "mb-14"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("We got you!")), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_a_typography_title, {
          level: 4,
          class: "mb-6"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Thank you for creating with us!")), 1)
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.t("Please wait for an email on how you can settle your balance.")), 1),
        _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.t(
            "Also, don't forget to choose your model creators or otherwise we will choose for you!"
          )), 1),
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.t(
            "If you haven't paid for this order, please send your order number to {email} and we will send you the payment link.",
            { email: _ctx.MERCHANT_PAYMENT_EMAIL }
          )), 1),
        _createVNode(_component_a_button, {
          type: "primary",
          size: "small",
          onClick: _ctx.goToDashboard
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.t("Go to dashboard")), 1)
          ]),
          _: 1
        }, 8, ["onClick"])
      ])
    ]),
    _: 1
  }))
}